<template>
  <v-footer>

  </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>
