<template>
  <div>
    <v-menu
        offset-y
        rounded="b-xl"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            class="ma-2"
            width="85%"
        >
          <img :src="getPic('flag_' + $i18n.locale + '.svg')" alt="flag" class="w-8 h-8 flag">
          <span class="ml-2">{{ $i18n.locale.toUpperCase() }}</span>
          <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path class="heroicon-ui"
                  d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"></path>
          </svg>
        </v-btn>
      </template>

      <v-list
          width="100%"
      >
        <v-list-item
            v-for="(item, index) in $i18n.availableLocales.filter( (locale, _ , __) => locale !== $i18n.locale ) "
            :key="index"
            link
            @click="setLocale(item)"
        >
          <img :src="getPic('flag_' + item + '.svg')" alt="flag" class="flag"/>
          <span class="ml-2">
            <v-list-item-title
                v-text="item.toUpperCase()"
            >

            </v-list-item-title>
          </span>

        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway'

export default {
  mixins: [clickaway],
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
    }
  },
  methods: {
    getPic(name) {

      console.log(this.$i18n.availableLocales)

      const pathToPics = require.context('../../assets/', false, /\.svg$/);
      return pathToPics('./'.concat(name))
    },
    hideDropdown() {
      this.isVisible = false
      this.focusedIndex = 0
    },
    setLocale(locale) {
      this.$i18n.locale = locale
      this.$router.push({
        params: {lang: locale}
      })
      this.hideDropdown()
    }
  }
}
</script>

<style scoped>

.flag {
  width: 35px;
  height: 35px;
}

</style>